import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import Box from "../components/box"
import Breadcrumb from "../components/breadcrumb"
import Button from "../components/button"
import Column from "../components/column"
import Columns from "../components/columns"
import Paragraph from "../components/paragraph"
import Divider from "../components/divider"
import Heading from "../components/heading"
import Image from "../components/image"
import Layout from "../layouts/default"
import PageTitle from "../components/page-title"
import Seo from "../components/seo"
import Stack from "../components/stack"

const AusdemArchivPage = () => {
  const data = useStaticQuery(graphql`
    query AusDemArchivIndexQuery {
      allFile(
        filter: {
          sourceInstanceName: { eq: "archiv" }
          extension: { eq: "mdx" }
        }
        sort: { order: DESC, fields: name }
      ) {
        edges {
          node {
            childMdx {
              id
              excerpt
              fields {
                slug
              }
              frontmatter {
                title
                author
                cover {
                  childImageSharp {
                    gatsbyImageData(width: 400, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
      ogImage: file(relativePath: { eq: "og_images/aus-dem-archiv.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
    }
  `)

  const { edges: episodes } = data.allFile

  return (
    <Layout backdrop="archiv">
      <Seo
        title="Aus dem Archiv geholt"
        description="In der Reihe ›Aus dem Archiv geholt‹ zeigt der Hölderlinturm wechselnde Originale aus verschiedenen Sammlungen – kommentiert von Gästen aus der Wissenschaft, Kunst und Kultur."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <Stack>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Digital",
                link: "/digital",
              },
              {
                title: "Aus dem Archiv geholt",
                link: "/aus-dem-archiv-geholt",
              },
            ]}
          />
          <PageTitle>Aus dem Archiv geholt</PageTitle>
          <Paragraph dropcap={true}>
            In der Reihe ›Aus dem Archiv geholt‹ zeigt der Hölderlinturm
            wechselnde Schaustücke aus verschiedenen Sammlungen: Manuskripte,
            Briefe und handschriftliche Dokumente von und an Hölderlin, die für
            einen kurzen Zeitraum im Hölderlinturm präsentiert werden. Jedes
            dieser Stücke wird dabei von einem wechselnden Gast kommentiert:
            Forscherinnen und Forscher, Schriftstellerinnen und Schriftsteller,
            Leserinnen und Leser gehen den Tintenklecksen und
            Entstehungshintergründen auf den Grund und werfen in einem kurzen,
            essayistischen Begleittext einen individuellen Blick auf die
            Objekte.
          </Paragraph>
          <Divider border={4} />
        </Stack>
        <Stack space={[12, 24]}>
          {episodes.map(({ node: episode }, index) => (
            <Columns
              collapse={[true, false]}
              space={[6, 12]}
              key={`${index}_${episode.id}`}
            >
              <Column width={[48, 64]}>
                <Box borderRadius="md" overflow="hidden" boxShadow="3xl">
                  <Link
                    to={`/aus-dem-archiv-geholt${episode.childMdx.fields.slug}`}
                  >
                    <Image
                      alt={`Titelbild zum Beitrag ${episode.childMdx.frontmatter.title} aus der Reihe Aus dem Archiv geholt`}
                      image={
                        episode.childMdx.frontmatter.cover.childImageSharp
                          .gatsbyImageData
                      }
                    />
                  </Link>
                </Box>
              </Column>
              <Column>
                <Stack space={6}>
                  <Stack space={1}>
                    <Link
                      to={`/aus-dem-archiv-geholt${episode.childMdx.fields.slug}`}
                    >
                      <Heading as="h2" level={3}>
                        {episode.childMdx.frontmatter.title}
                      </Heading>
                    </Link>
                    {episode.childMdx.frontmatter.author && (
                      <Heading
                        as="strong"
                        level={5}
                        bold={false}
                        color="whisper"
                      >
                        kommentiert von {episode.childMdx.frontmatter.author}
                      </Heading>
                    )}
                  </Stack>
                  {episode.childMdx.excerpt && (
                    <Paragraph>{episode.childMdx.excerpt}</Paragraph>
                  )}
                  <Button
                    to={`/aus-dem-archiv-geholt${episode.childMdx.fields.slug}`}
                  >
                    Jetzt lesen
                  </Button>
                </Stack>
              </Column>
            </Columns>
          ))}
        </Stack>
      </Stack>
    </Layout>
  )
}

export default AusdemArchivPage
